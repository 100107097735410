if (document.readyState !== 'loading') {
    console.log('document ready in asset analytics');
    initAssetTracking();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        console.log('document was not ready in asset analytics');
        initAssetTracking();
    });
}

function initAssetTracking() {
    const assetsToTrack = document.querySelectorAll('[data-analytic-track="true"]')
    if (assetsToTrack.length > 0) {
        console.log('Found assets to track:', assetsToTrack)
        assetsToTrack.forEach(asset => {
            asset.addEventListener('click', function reportAssetData(e) {
                const data = asset.dataset
                dataLayer.push({
                    event: 'assetInteraction',
                    asset: data
                })
                console.log('Asset data sent to dataLayer.', data)

            })
        })
 
    }
}