import $ from "jquery";
global.$ = global.jQuery = $;

import * as _ from "lodash";

import feather from "feather-icons";
window.feather = feather;

import "bigSlide";

import "./vendor/jquery-3.3.1.min";
import "./vendor/jquery.touchSwipe.min";
import "./vendor/jquery.waypoint.min";
import "./vendor/slick.min";

import "./ox/app";
import "./ox/analytics";
import "./ox/loading-bar";
import "./ox/snackbar";
import "./ox/account";
import "./ox/cart";
import "./ox/collapse";
import "./ox/dealer-locator";
import "./ox/hero-carousel";
import "./ox/image-gallery";
import "./ox/job-listing";
import "./ox/life-listing";
import "./ox/modal";
import "./ox/newsletter-signup";
import "./ox/newsletter-signup-inline";
import "./ox/overview-feature-promo";
import "./ox/overview-intro";
import "./ox/page-navigation";
import "./ox/parallax-product-promo";
import "./ox/product-detail-gallery";
import "./ox/product-highlight-carousel";
import "./ox/product-listing";
import "./ox/product-registration";
import "./ox/responsive-images";
import "./ox/search-results";
import "./ox/segmented-controls";
import "./ox/serial-lookup";
import "./ox/service-landing";
import "./ox/service-listing";
import "./ox/service-prodsearch";
import "./ox/three-up";
import "./ox/video-gallery";
import "./ox/video-slider";
import "./ox/multi-promo-slider";
import "./ox/youtube-init";
import "./ox/worldpay-checkout";
import "./ox/analytic-asset-reporting";

import "./qrscanner/qr-scanner";

import "../scss/main.scss";
import "../../node_modules/swiper/swiper.min.css";

!(function ($) {
  $(function () {
    const $root = $("html, body");
    const $body = $("body");
    const $window = $(window);
    const $header = $("header");
    const $headerWrapper = $(".header-wrapper");
    const $headerSearchTrigger = $(
      "#header-search-trigger, #mobile-search-toggle"
    );
    const $headerSearchCancel = $("#header-search-cancel");
    const $headerSearchBar = $("#header-search");
    const $headerSearchInput = $("#header-search-input");

    //-------------------------
    // Header
    //-------------------------
    const cssBodyMenuOpen = "menu-open";
    const cssHeaderOpen = "header--menuopen";
    const cssHeaderSubOpen = "header--submenuopen";
    const cssSubMenuOpen = "header-megamenu-section-wrapper--open";
    const cssSearchOpen = "header-search--open";
    const $sectionTitles = $(".header-megamenu-section-title");
    const $submenus = $(".header-megamenu-section-wrapper");
    const $submenuClose = $(".header-megamenu-section-back");

    $("#mobile-menu-toggle").on("click", function (e) {
      e.preventDefault();
      if ($header.hasClass(cssHeaderOpen)) {
        $header.removeClass(cssHeaderOpen);
        $body.removeClass(cssBodyMenuOpen);
      } else {
        $header.addClass(cssHeaderOpen);
        $body.addClass(cssBodyMenuOpen);
      }
    });
    enquire.register("screen and (max-width:1024px)", {
      match: function () {
        $sectionTitles.on("click.header", function (e) {
          e.preventDefault();
          const $submenu = $(this).next();
          if (!$submenu.hasClass(cssSubMenuOpen)) {
            $submenus.removeClass(cssSubMenuOpen);
            $submenu.addClass(cssSubMenuOpen);
            $header.addClass(cssHeaderSubOpen);
            $headerWrapper.scrollTop(0);
          }
        });
      },
      unmatch: function () {
        $sectionTitles.off("click.header");
        $submenus.removeClass(cssSubMenuOpen);
        $submenus.removeClass(cssSubMenuOpen);
        $header.removeClass(cssHeaderSubOpen);
      },
    });
    $submenuClose.on("click", function (e) {
      e.preventDefault();
      $submenus.removeClass(cssSubMenuOpen);
      $header.removeClass(cssHeaderSubOpen);
    });

    $headerSearchTrigger.on("click", function (e) {
      e.preventDefault();
      $headerSearchBar.addClass(cssSearchOpen);
      $headerSearchTrigger.addClass("active");
      $headerSearchInput.focus();
    });

    $headerSearchCancel.on("click", function (e) {
      e.preventDefault();
      $headerSearchBar.removeClass(cssSearchOpen);
      $headerSearchTrigger.removeClass("active");
    });

    //-------------------------
    // Localization Menus
    //-------------------------
    const $localizationMenus = $(".localization-menu");
    const cssLocalizationMenuOpen = "localization-menu--active";
    enquire.register("screen and (max-width:1024px)", {
      match: function () {
        $localizationMenus.on("click", function () {
          const $this = $(this);
          if ($this.hasClass(cssLocalizationMenuOpen)) {
            $this.removeClass(cssLocalizationMenuOpen);
          } else {
            $this.addClass(cssLocalizationMenuOpen);
          }
        });
      },
      unmatch: function () {
        $localizationMenus.on("click", function () {});
      },
    });

    //-------------------------
    // Form Inputs
    //-------------------------
    $(document)
      .on(
        "focus",
        "input:not([type=radio], [type=checkbox]), textarea",
        function () {
          $(this).parents(".field").addClass("focus");
        }
      )
      .on(
        "blur",
        "input:not([type=radio], [type=checkbox]), textarea",
        function () {
          const $this = $(this);
          const $parent = $this.parents(".field");

          $parent.removeClass("focus");

          if ($this.val() == "") {
            return $parent.removeClass("has-value");
          }
          $parent.addClass("has-value");
        }
      );

    //-------------------------
    // Universal Smooth Scrolling
    //-------------------------
    $('a[href^="#"]').on("click", function (e) {
      e.preventDefault();
      const hash = $(this).attr("href").substring(1);
      if (hash.length) {
        const $target = $("a[name=" + hash + "]");
        if ($target.length) {
          // set hash without scrolling
          const originalScrollTop = $window.scrollTop();
          window.location.hash = hash;
          $root.scrollTop(originalScrollTop);
          const offset = $target.offset().top;
          $root.animate({ scrollTop: offset - 45 }, 500);
        }
      }
    });

    $(".contact-us-section").each(function () {
      var $this = $(this);
      var height = 0;
      $this.find(".contact-us-section-column header").each(function () {
        var h = $(this).height();
        height = h > height ? h : height;
      });
      height += "px";
      $this.find(".contact-us-section-column header").css({
        height,
      });
    });

    if ($(".contact-us").length) {
      $(".content").removeClass("content");
    }

    const loadFeather = () => {
      feather.replace();
      _.delay(() => loadFeather(), 500);
    };
    loadFeather();
  });
})(window.jQuery);
